<template>
  <div style="background-color: #fff" id="page-pendaftaran">
    <b-container fluid>
      <!-- <pre>{{ dataRm || [] }}</pre> -->
      <b-row style="padding-top: 15px">
        <b-col cols="12" md="12" lg="8" xl="8">
          <div
            style="
              width: 100%;
              height: 40px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background-color: ;
            "
          >
            <h6
              style="font-weight: 700; color: #9c4098; font-size: 22px; margin-bottom: 0">
              Detail Rekam Medis Pasien
            </h6>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <hr />
        </b-col>
      </b-row>

      <b-row class="pb-4">
        <b-col cols="12">
          <b-row style="margin: 0px -3px;">
            <b-col cols="12">
              <h6
                style="font-weight: 700; color: #9c4098; font-size: 16px; margin-bottom: 0">
                Puskesmas
              </h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="nama_puskesmas">Puskesmas</label>
              <h6 class=""><strong>{{dataRm.nama_puskesmas || '-'}}</strong></h6>
            </b-col>
            <b-col cols="48" class="pl-3">
              <label for="alamat_puskesmas">Alamat Puskesmas</label>
              <h6 class=""><strong>{{dataRm.alamat_puskesmas || '-'}}</strong></h6>
            </b-col>
            <!-- <b-col cols="4" class="pl-3">
              <label for="koordinat_puskesmas">Koordinat Puskesmas</label>
              <h6 class=""><strong>{{dataRm.koordinat_puskesmas || '-'}}</strong></h6>
            </b-col> -->
            <b-col cols="12">
              <h6
                style="font-weight: 700; color: #9c4098; font-size: 16px; margin-bottom: 0">
                Data Pasien
              </h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="nama_lengkap">Nama Lengkap</label>
              <h6 class=""><strong>{{dataRm.nama_lengkap || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="nik">NIK</label>
              <h6 class=""><strong>{{dataRm.nik || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="no_rm">No. RM</label>
              <h6 class="" style="color: #9c4098;"><strong>{{dataRm.no_rm || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="tanggal_lahir">Tanggal Lahir</label>
              <h6 class=""><strong>{{$moment(dataRm.tanggal_lahir).format('LL') || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="jenis_kelamin">Jenis Kelamin</label>
              <h6 class=""><strong>{{dataRm.jenis_kelamin == 'L' ? 'Laki - Laki' : 'Perempuan' || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="agama">Agama</label>
              <h6 class=""><strong>{{dataRm.agama || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="no_telp">No. Telepon</label>
              <h6 class=""><strong>{{dataRm.no_telp || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="no_bpjs">No. BPJS</label>
              <h6 class=""><strong>{{dataRm.no_bpjs || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="no_asuransi_lain">No. Asuransi Lain</label>
              <h6 class=""><strong>{{dataRm.no_asuransi_lain || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="status_keluarga">Status</label>
              <h6 class=""><strong>{{dataRm.status_keluarga || '-'}} / {{dataRm.status_perkawinan || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="asal_negara">Asal Negara</label>
              <h6 class=""><strong>{{dataRm.asal_negara || '-'}} / {{dataRm.nama_etnis || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="no_paspor">No. Pasport</label>
              <h6 class=""><strong>{{dataRm.no_paspor || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="nama_golongan_darah">Golongan Darah</label>
              <h6 class=""><strong>{{dataRm.nama_golongan_darah || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="pekerjaan">Pekerjaan</label>
              <h6 class=""><strong>{{dataRm.pekerjaan || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="npwp">NPWP</label>
              <h6 class=""><strong>{{dataRm.npwp || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="ihs">IHS</label>
              <h6 class=""><strong>{{dataRm.satu_sehat_id || '-'}}</strong></h6>
            </b-col>
            <b-col cols="12">
              <h6
                style="font-weight: 700; color: #9c4098; font-size: 16px; margin-bottom: 0">
                Penyakit
              </h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="no_kk">Diabetes</label>
              <h6 class=""><strong>{{dataRm.pst_prb || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="no_kk">Prolanis</label>
              <h6 class=""><strong>{{dataRm.pst_prol || '-'}}</strong></h6>
            </b-col>
            <b-col cols="12">
              <h6
                style="font-weight: 700; color: #9c4098; font-size: 16px; margin-bottom: 0">
                Keluarga
              </h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="no_kk">No KK</label>
              <h6 class=""><strong>{{dataRm.no_kk || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="nama_ayah">Ayah</label>
              <h6 class=""><strong>{{dataRm.nama_ayah || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="nama_ibu">Ibu</label>
              <h6 class=""><strong>{{dataRm.nama_ibu || '-'}}</strong></h6>
            </b-col>
            <b-col cols="12">
              <h6
                style="font-weight: 700; color: #9c4098; font-size: 16px; margin-bottom: 0">
                Penjamin
              </h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="nama_penjamin">Penjamin</label>
              <h6 class=""><strong>{{dataRm.nama_penjamin || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="hubungan_penjamin">Hubungan Penjamin</label>
              <h6 class=""><strong>{{dataRm.hubungan_penjamin || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="no_telp_penjamin">No. Telepon Penjamin</label>
              <h6 class=""><strong>{{dataRm.no_telp_penjamin || '-'}}</strong></h6>
            </b-col>
            <b-col cols="12">
              <h6
                style="font-weight: 700; color: #9c4098; font-size: 16px; margin-bottom: 0">
                Alamat
              </h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="alamat_sekarang">Alamat Sekarang</label>
              <h6 class=""><strong>{{dataRm.alamat_sekarang || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="alamat_ktp">Alamat KTP</label>
              <h6 class=""><strong>{{dataRm.alamat_ktp || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="nama_kelurahan">Kelurahan</label>
              <h6 class=""><strong>{{dataRm.nama_kelurahan || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="nama_kecamatan">Kecamatan</label>
              <h6 class=""><strong>{{dataRm.nama_kecamatan || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="nama_kota">Kecamatan</label>
              <h6 class=""><strong>{{dataRm.nama_kota || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="pl-3">
              <label for="nama_provinsi">Provinsi</label>
              <h6 class=""><strong>{{dataRm.nama_provinsi || '-'}}</strong></h6>
            </b-col>
          </b-row>
          <!-- <pre>{{rm}}</pre> -->
        </b-col>

        <!-- <b-col cols="12" md="12" lg="12">
          <hr>
        </b-col> -->

        <b-col class="mt-3" cols="12" md="12" lg="12">
          <b-container fluid>
            <b-row align-h="end">
              <b-col cols="auto">
                <b-button
                  class="mr-2"
                  size="sm"
                  @click="printCetak58('print_label_1')"
                  v-c-tooltip.hover.click="'Cetak Label 1'"
                >
                  <!-- <CIcon name="cil-info" /> -->
                  Cetak Label 1
                </b-button>
                
                <b-button
                  class="mr-2"
                  size="sm"
                  @click="printCetak58('print_label_2')"
                  v-c-tooltip.hover.click="'Cetak Label 2'"
                >
                  <!-- <CIcon name="cil-info" /> -->
                  Cetak Label 2
                </b-button>

                <b-button
                  class="mr-2"
                  variant="info"
                  size="sm"
                  @click="printCetak('kartu_rekam_medis')"
                  v-c-tooltip.hover.click="'Cetak Kartu Rekam Medis'"
                >
                  <!-- <CIcon name="cil-info" /> -->
                  Cetak Kartu RM
                </b-button>
                
                <b-button
                  class="mr-2"
                  variant="warning text-white"
                  size="sm"
                  @click="printDataRM('rekam_medis_pasien')"
                  v-c-tooltip.hover.click="'Cetak Rekam Medis'"
                >
                  <!-- <CIcon name="cil-info" /> -->
                  Cetak Rekam Medis
                </b-button>
                
                <b-button
                  variant="danger"
                  size="sm"
                  @click="printCetak('blanko_rekam_medis')"
                  v-c-tooltip.hover.click="'Cetak Blanko Kosong'"
                >
                  Cetak Blanko Kosong
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
        
        <b-col cols="12" md="12" lg="12">
          <hr>
        </b-col>
        
        <b-col cols="12" md="12" lg="12">
          <div class="d-flex justify-content-between mb-2">
            <h6
              class="mt-4"
              style="font-weight: 700; color: #9c4098; font-size: 18px; margin-bottom: 0"
            >
              List 10 Kunjungan Terakhir
            </h6>
            <b-button
              variant="primary"
              size="sm"
              class="ml-1 mt-3"
              style="font-weight: 700; padding: 0px 10px;"
              @click="$router.push('../detail_kunjungan_by_rekam_medis/' + dataRm.rm_id)"
              v-c-tooltip.hover.click="'Daftar Semua Kunjungan Pasien'"
            >
              <!-- <CIcon name="cil-info" /> -->
              List Semua Kunjungan Pasien
            </b-button>
          </div>
          <b-table
            :items="listKunjungan"
            :fields="fieldsKunjungan"
            responsive
            show-empty
            small
            bordered
            striped
            hover
            :busy="busy"
          >
            <template #cell(no)="item">
              {{item.index + 1}}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>

    <!-- PRINT LABEL 1 -->
    <div class="w-100" style="display: none;" id="print_label_1">
      <section class="content-print paper-58">
        <div class="sheet custom-border-paper">
          <div class="w-100">
            <table border="0" class="custom-table">
              <tbody>
                <tr>
                  <td style="width: 17mm;padding:0.5mm;font-size:8pt">Nama</td>
                  <td style="width: 2.5mm;text-align: center;padding:0.5mm;font-size:8pt">:</td>
                  <td style="padding:0.5mm;font-size:8pt">{{ dataRm.nama_lengkap }}</td>
                </tr>

                <tr>
                  <td style="padding:0.5mm;font-size:8pt">No. RM</td>
                  <td style="text-align: center;padding:0.5mm;font-size:8pt">:</td>
                  <td style="padding:0.5mm;font-size:8pt">{{ dataRm.no_rm }}</td>
                </tr>

                <tr>
                  <td style="padding:0.5mm;font-size:8pt">Tgl. Lahir</td>
                  <td style="text-align: center;padding:0.5mm;font-size:8pt">:</td>
                  <td style="padding:0.5mm;font-size:8pt">{{ $moment(dataRm.tanggal_lahir).format("DD-MM-YYYY") || '-' }}</td>
                </tr>

                <tr>
                  <td style="padding:0.5mm;font-size:8pt">Jns. Kelamin</td>
                  <td style="text-align: center;padding:0.5mm;font-size:8pt">:</td>
                  <td style="padding:0.5mm;font-size:8pt">{{ dataRm.jenis_kelamin == 'L' ? 'Laki - Laki' : 'Perempuan' }}</td>
                </tr>

                <tr>
                  <td style="padding:0.5mm;font-size:8pt">Alamat</td>
                  <td style="text-align: center;padding:0.5mm;font-size:8pt">:</td>
                  <td style="padding:0.5mm;font-size:8pt">{{ dataRm.alamat_sekarang }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
    <!-- PRINT LABEL 2 -->
    <div class="w-100" style="display: none;" id="print_label_2">
      <section class="content-print paper-58">
        <div class="sheet custom-border-paper">
          <div class="w-100">
            <table border="0" class="custom-table">
              <tbody>
                <tr>
                  <td style="width: 17mm;padding:0.5mm;font-size:8pt">No. RM</td>
                  <td style="width: 2.5mm;text-align: center;padding:0.5mm;font-size:8pt">:</td>
                  <td style="padding:0.5mm;font-size:8pt">{{ dataRm.no_rm }}</td>
                </tr>

                <tr>
                  <td style="padding:0.5mm;font-size:8pt">Nama</td>
                  <td style="text-align: center;padding:0.5mm;font-size:8pt">:</td>
                  <td style="padding:0.5mm;font-size:8pt">{{ dataRm.nama_lengkap }}</td>
                </tr>

                <tr>
                  <td style="padding:0.5mm;font-size:8pt">Tgl. Lahir</td>
                  <td style="text-align: center;padding:0.5mm;font-size:8pt">:</td>
                  <td style="padding:0.5mm;font-size:8pt">{{ $moment(dataRm.tanggal_lahir).format("DD-MM-YYYY") || '-' }}</td>
                </tr>

                <tr>
                  <td style="padding:0.5mm;font-size:8pt">Jns. Kelamin</td>
                  <td style="text-align: center;padding:0.5mm;font-size:8pt">:</td>
                  <td style="padding:0.5mm;font-size:8pt">{{ dataRm.jenis_kelamin == 'L' ? 'Laki - Laki' : 'Perempuan' }}</td>
                </tr>

                <tr>
                  <td style="padding:0.5mm;font-size:8pt">Alamat</td>
                  <td style="text-align: center;padding:0.5mm;font-size:8pt">:</td>
                  <td style="padding:0.5mm;font-size:8pt">{{ dataRm.alamat_sekarang }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
    <!-- PRINT KARTU RM -->
    <div style="width:100%;display: none;" id="kartu_rekam_medis">
      <section class="content-print A4">
        <div class="sheet">
          <div class="w-100">
            <table border="0" class="custom-table">
                <tbody>
                    <tr>
                        <td style="width: 15%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img :src="logo" alt="" style="width: 100%;"></td>
                        <td style="width: 70%;vertical-align: middle;">
                            <h6 style="text-align: center;font-weight: bold;margin-bottom: 6px;font-size: 16pt;">PEMERINTAH <span>{{ $store.state.puskesmas.nama_kota }}</span> </h6>
                            <h6 style="text-align: center;font-weight: bold;margin-bottom: 6px;font-size: 16pt;"><span>DINAS KESEHATAN</span></h6>
                            <h6 style="text-align: center;font-weight: bold;margin-bottom: 6px;font-size: 16pt;"> <span>{{ $store.state.puskesmas.nama_puskesmas }}</span> </h6>
                            <h6 style="text-align: center;font-weight: normal;font-size: 10pt;">Alamat: <span>{{ $store.state.puskesmas.alamat_puskesmas }}</span></h6>
                            <h6 style="text-align: center;font-weight: normal;font-size: 10pt;"><span>Email : emailpuskesmas@gmil.com</span> <span>Telp. : 0987654321</span></h6>
                        </td>
                        <td style="width: 15%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img src="../../cetak/puskesmas-logo.png" alt="" style="width: 100%;"></td>
                    </tr>
                </tbody>
            </table>
          </div>

          <div class="w-100" style="margin-top:2.5mm">
              <div class="line"></div>
          </div>

          <div class="w-100" style="margin-top: 5mm;">
              <h6 style="font-size: 16pt;font-weight: bold;text-align: center;"><u>KARTU REKAM MEDIS PASIEN</u></h6>

              <h6 style="font-size: 16pt;font-weight: bold;text-align: center;margin-top:5mm"><u>Data Pasien</u></h6>
          </div>

          <div class="w-100-flex" style="margin-top: 10mm;">
            <div style="width: 100%;">
              <table border="0" class="custom-table">
                <tbody>
                  <tr>
                    <td style="width: 50mm;">No. eRM</td>
                    <td class="autowidth">:</td>
                    <td><span>{{ dataRm.no_rm }}</span></td>
                  </tr>

                  <!-- <tr>
                      <td>No. RM Lama</td>
                      <td>:</td>
                      <td><span>-</span></td>
                  </tr> -->

                  <!-- <tr>
                      <td>No. Dok RM</td>
                      <td>:</td>
                      <td><span>-</span></td>
                  </tr> -->

                  <tr>
                    <td>Nama</td>
                    <td>:</td>
                    <td><span>{{ dataRm.nama_lengkap }}</span></td>
                  </tr>

                  <tr>
                    <td>No. KK</td>
                    <td>:</td>
                    <td><span>{{ dataRm.no_kk }}</span></td>
                  </tr>

                  <tr>
                    <td>NIK</td>
                    <td>:</td>
                    <td><span>{{ dataRm.nik }}</span></td>
                  </tr>

                  <tr>
                    <td>Jenis Kelamin</td>
                    <td>:</td>
                    <td><span>{{ dataRm.jenis_kelamin == 'L' ? 'Laki - Laki' : 'Perempuan' || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Tempat / Tanggal Lahir</td>
                    <td>:</td>
                    <td><span>{{ dataRm.tempat_lahir || '-' }} / {{ $moment(dataRm.tanggal_lahir).format('LL') || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Golongan Darah</td>
                    <td>:</td>
                    <td><span>{{ dataRm.nama_golongan_darah || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Email</td>
                    <td>:</td>
                    <td><span>{{ dataRm.email || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>No. Hp</td>
                    <td>:</td>
                    <td><span>{{ dataRm.no_telp || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Alamat</td>
                    <td>:</td>
                    <td><span>{{ dataRm.alamat_sekarang || '-' }}</span></td>
                  </tr>

                  <!-- <tr>
                      <td>RT/RW</td>
                      <td>:</td>
                      <td><span>-</span></td>
                  </tr> -->

                  <tr>
                    <td>Provinsi</td>
                    <td>:</td>
                    <td><span>{{ dataRm.nama_provinsi || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Kota</td>
                    <td>:</td>
                    <td><span>{{ dataRm.nama_kota || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Kecamatan</td>
                    <td>:</td>
                    <td><span>{{ dataRm.nama_kecamatan || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Kelurahan</td>
                    <td>:</td>
                    <td><span>{{ dataRm.nama_kelurahan || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Dusun</td>
                    <td>:</td>
                    <td><span>{{ dataRm.nama_dusun || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Pekerjaan</td>
                    <td>:</td>
                    <td><span>{{ dataRm.pekerjaan || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Agama</td>
                    <td>:</td>
                    <td><span>{{ dataRm.agama || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Status Perkawinan</td>
                    <td>:</td>
                    <td><span>{{ dataRm.status_perkawinan || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Status Keluarga</td>
                    <td>:</td>
                    <td><span>{{ dataRm.status_keluarga || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Warga Negara</td>
                    <td>:</td>
                    <td><span>{{ dataRm.asal_negara || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>No. Paspor</td>
                    <td>:</td>
                    <td><span>{{ dataRm.no_paspor || '-' }}</span></td>
                  </tr>

                  <!-- <tr>
                      <td>No. KITAS/KITAP</td>
                      <td>:</td>
                      <td><span>-</span></td>
                  </tr> -->

                  <tr>
                    <td>Nama Ayah</td>
                    <td>:</td>
                    <td><span>{{ dataRm.nama_ayah || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Nama Ibu</td>
                    <td>:</td>
                    <td><span>{{ dataRm.nama_ibu || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Asuransi</td>
                    <td>:</td>
                    <td><span>{{ dataRm.no_bpjs ? `BPJS - ${dataRm.no_bpjs}` : "UMUM" || '-' }}</span></td>
                  </tr>
                  
                  <tr>
                    <td>Asuransi Lain</td>
                    <td>:</td>
                    <td><span>{{ dataRm.no_asuransi_lain || '-' }}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- PRINT RM -->
    <div style="width:100%;display: none;" id="rekam_medis_pasien">
      <section class="content-print A4">
        <div class="sheet">
          <div class="w-100">
            <table border="0" class="custom-table">
              <tbody>
                <tr>
                  <td style="width: 15%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img :src="logo" alt="" style="width: 100%;"></td>
                  <td style="width: 70%;vertical-align: middle;">
                    <h6 style="text-align: center;font-weight: bold;margin-bottom: 6px;font-size: 16pt;"> PEMERINTAH <span>{{ $store.state.puskesmas.nama_kota }}</span> </h6>
                    <h6 style="text-align: center;font-weight: bold;margin-bottom: 6px;font-size: 16pt;"><span>DINAS KESEHATAN</span></h6>
                    <h6 style="text-align: center;font-weight: bold;margin-bottom: 6px;font-size: 16pt;"> <span>{{ $store.state.puskesmas.nama_puskesmas }}</span> </h6>
                    <h6 style="text-align: center;font-weight: normal;font-size: 10pt;">Alamat: <span>{{ $store.state.puskesmas.alamat_puskesmas }}</span></h6>
                    <h6 style="text-align: center;font-weight: normal;font-size: 10pt;"><span>Email : emailpuskesmas@gmil.com</span> <span>Telp. : 0987654321</span></h6>
                  </td>
                  <td style="width: 15%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img src="../../cetak/puskesmas-logo.png" alt="" style="width: 100%;"></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="w-100" style="margin-top:2.5mm">
            <div class="line"></div>
          </div>

          <div class="w-100" style="margin-top: 5mm;">
            <h6 style="font-size: 16pt;font-weight: bold;text-align: center;"><u>Rekam Medis Pasien</u></h6>
          </div>

          <div class="w-100-flex" style="margin-top: 5mm;">
            <div style="width: 50%;">
              <table border="0" class="custom-table">
                <tbody>
                  <tr>
                    <td style="width: 37mm;">No. eRM</td>
                    <td class="autowidth">:</td>
                    <td><span>{{ dataRm.no_rm || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Nama Pasien</td>
                    <td>:</td>
                    <td><span>{{ dataRm.nama_lengkap || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>NIK</td>
                    <td>:</td>
                    <td><span>{{ dataRm.nik || '-' }}</span></td>
                  </tr>
                  
                  <tr>
                    <td>Jenis Kelamin</td>
                    <td>:</td>
                    <td><span>{{ dataRm.jenis_kelamin == 'L' ? 'Laki - Laki' : 'Perempuan' || '-' }}</span></td>
                  </tr>

                  <!-- <tr>
                      <td>No. RM Lama</td>
                      <td>:</td>
                      <td><span>-</span></td>
                  </tr> -->

                  <!-- <tr>
                      <td>No. Dok RM</td>
                      <td>:</td>
                      <td><span>-</span></td>
                  </tr> -->

                  <!-- <tr>
                    <td>No. Asuransi</td>
                    <td>:</td>
                    <td><span>-</span></td>
                  </tr> -->
                  
                  <tr>
                    <td>Asuransi</td>
                    <td>:</td>
                    <td><span>{{ dataRm.no_bpjs ? `BPJS - ${dataRm.no_bpjs}` : "UMUM" || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>No. Handphone</td>
                    <td>:</td>
                    <td><span>{{ dataRm.no_telp || '-' }}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style="width: 50%;">
              <table border="0" class="custom-table">
                <tbody>
                  <tr>
                    <td style="width: 37mm;">Tanggal Lahir</td>
                    <td class="autowidth">:</td>
                    <td><span>{{ $moment(dataRm.tanggal_lahir).format('LL') || '-' }}</span></td>
                  </tr>
                  
                  <tr>
                    <td>Tempat Lahir</td>
                    <td>:</td>
                    <td><span>{{ dataRm.tempat_lahir || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Umur</td>
                    <td>:</td>
                    <td><span>{{ cekUmur(dataRm.tanggal_lahir) || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Golongan Darah</td>
                    <td>:</td>
                    <td><span>{{ dataRm.nama_golongan_darah || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>No. KK</td>
                    <td>:</td>
                    <td><span>{{ dataRm.no_kk || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Alamat</td>
                    <td>:</td>
                    <td><span>{{ dataRm.alamat_sekarang || '-' }}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div style="width:100%;margin-top: 5mm;" v-if="dataPrintRM">
            <table border="1" class="custom-table table-custom3">
              <thead>
                <tr>
                  <th class="autowidth" style="font-size:8pt;padding:1mm">No.</th>
                  <th style="font-size:8pt;padding:1mm">Tanggal</th>
                  <th style="font-size:8pt;padding:1mm">Anamnesa</th>
                  <th style="font-size:8pt;padding:1mm">Pemeriksaan Fisik</th>
                  <th style="font-size:8pt;padding:1mm">Pemeriksaan Laboratorium</th>
                  <th style="font-size:8pt;padding:1mm">Diagnosa</th>
                  <th style="font-size:8pt;padding:1mm">Tindakan</th>
                  <th style="font-size:8pt;padding:1mm">Resep Obat</th>
                  <th style="font-size:8pt;padding:1mm">Poli/Ruangan</th>
                  <th style="font-size:8pt;padding:1mm">Kamar</th>
                  <th style="font-size:8pt;padding:1mm">Rujuk Internal</th>
                  <th style="font-size:8pt;padding:1mm">Rujuk Eksternal</th>
                  <th style="font-size:8pt;padding:1mm">TTD</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(el_data, index) in dataPrintRM" :key="el_data.kunjungan_id">
                  <td style="font-size:8pt;padding:1mm">{{ index + 1 }}</td>
                  <td style="font-size:8pt;padding:1mm">{{ $moment(el_data.tanggal).format('LLL') }}</td>
                  <td style="padding:1mm">
                    <ul v-if="el_data.anamnesa" class="custom-li"  style="font-size: 8pt;padding-left:5px;">
                      <li>
                        Keluhan Utama: {{ el_data.anamnesa.keluhan_utama || '-' }}
                      </li>
                      <li>
                        Keluhan Tambahan: {{ el_data.anamnesa.keluhan_tambahan || '-' }}
                      </li>
                      <li>
                        Lama Sakit: 
                        <span v-if="el_data.anamnesa.lama_sakit_tahun">
                          {{ el_data.anamnesa.lama_sakit_tahun || '-' }} Tahun
                          <br>
                        </span>
                        <span v-if="el_data.anamnesa.lama_sakit_bulan">
                          {{ el_data.anamnesa.lama_sakit_bulan || '-' }} Bulan
                          <br>
                        </span>
                        <span v-if="el_data.anamnesa.lama_sakit_hari">
                          {{ el_data.anamnesa.lama_sakit_hari || '-' }} Hari
                        </span>
                      </li>
                      <li>
                        Merokok: {{ el_data.anamnesa.merokok ? 'Ya' : 'Tidak' }}
                      </li>
                      <li>
                        Konsumsi Alkohol: {{ el_data.anamnesa.konsumsi_alkohol ? 'Ya' : 'Tidak' }}
                      </li>
                      <li>
                        Kurang Sayur Buah: {{ el_data.anamnesa.kurang_sayur_buah ? 'Ya' : 'Tidak' }}
                      </li>
                      <li>
                        Edukasi: {{ el_data.anamnesa.edukasi || '-' }}
                      </li>
                      <li>
                        Terapi Sebelum: {{ el_data.anamnesa.terapi || '-' }}
                      </li>
                      <li>
                        Rencana Tindakan: {{ el_data.anamnesa.rencana_tindakan || '-' }}
                      </li>
                      <li>
                        Observasi: {{ el_data.anamnesa.observasi || '-' }}
                      </li>
                      <li>
                        Biopsikososial: {{ el_data.anamnesa.biopsikososial || '-' }}
                      </li>
                      <li>
                        Keterangan: {{ el_data.anamnesa.keterangan_pool_anamnesa || '-' }}
                      </li>
                    </ul>
                    <h6 style="font-size:8pt;font-weight: bold;">Data Alergi:</h6>
                    <ul class="custom-li" style="font-size:8pt;padding-left:5px;">
                      <li v-for="el_alergi in el_data.anamnesa[1]">
                        Obat {{ el_alergi.data_alergi || '-' }}
                      </li>
                      <li v-for="el_alergi in el_data.anamnesa[1]">
                        Makanan {{ el_alergi.data_alergi || '-' }}
                      </li>
                      <li v-for="el_alergi in el_data.anamnesa[1]">
                        Umum {{ el_alergi.data_alergi || '-' }}
                      </li>
                    </ul>
                  </td>
                  <td style="padding:1mm">
                    <ul v-for="el_fisik in el_data.pemeriksaan_fisik" class="custom-li" style="font-size: 8pt;padding-left:5px" >
                      <!-- <li v-for="(data, key) in el_fisik">
                        {{ key }}: {{ data || '-' }}
                      </li> -->
                      <li>
                        Sadar: {{ el_fisik.nama_sadar || "-" }}
                      </li>
                      <li>
                        Sistole: {{ el_fisik.sistole || "-" }}
                      </li>
                      <li>
                        Diastole: {{ el_fisik.diastole || "-" }}
                      </li>
                      <li>
                        TB: {{ el_fisik.tinggi_badan || "-" }}
                      </li>
                      <li>
                        BB: {{ el_fisik.berat_badan || "-" }}
                      </li>
                      <li>
                        Lingkar Perut: {{ el_fisik.lingkar_perut || "-" }}
                      </li>
                      <li>
                        Detak Nadi: {{ el_fisik.detak_nadi || "-" }}
                      </li>
                      <li>
                        Nafas: {{ el_fisik.nafas || "-" }}
                      </li>
                      <li>
                        Saturasi: {{ el_fisik.saturasi || "-" }}
                      </li>
                      <li>
                        Suhu: {{ el_fisik.suhu || "-" }}
                      </li>
                      <li>
                        Aktifitas Fisik: {{ el_fisik.aktifitas_fisik || "-" }}
                      </li>
                      <li>
                        Detak Jantung: {{ el_fisik.detak_jantung.toUpperCase() || "-" }}
                      </li>
                      <li>
                        Triage: {{ el_fisik.triage.toUpperCase() || "-" }}
                      </li>
                      <li>
                        Skala Nyeri: {{ el_fisik.skala_nyeri || "-" }}
                      </li>
                    </ul>
                  </td>
                  <td style="padding:1mm">
                    <ul v-for="el_laboratorium in el_data.pemeriksaan_laboratorium" style="font-size:8pt;padding-left:5px">
                      <li v-for="(data, key) in el_laboratorium">
                        {{ key }}: {{ data || '-' }}
                      </li>
                    </ul>
                  </td>
                  <td style="padding:1mm">
                    <ul v-for="el_diagnosa in el_data.diagnosa" class="custom-li" style="font-size:8pt;padding-left:5px">
                      <!-- <li v-for="(data, key) in el_diagnosa">
                        {{ key }}: {{ data || '-' }}
                      </li> -->
                      <li>
                        {{ el_diagnosa.nama_diagnosa  }} ({{ el_diagnosa.kode_diagnosa || "-" }})
                      </li>
                    </ul>
                  </td>
                  <td style="padding:1mm">
                    <ul v-for="el_tindakan in el_data.tindakan" class="custom-li" style="font-size:8pt;padding-left:5px">
                      <li v-for="(data, key) in el_tindakan">
                        {{ key }}: {{ data || '-' }}
                      </li>
                    </ul>
                  </td>
                  <td style="padding:1mm">
                    <ul v-for="el_resep_obat in el_data.resep_obat" class="custom-li" style="font-size:8pt;padding-left:5px">
                      <li  v-for="(data, key) in el_resep_obat">
                        {{ key }}: {{ data || '-' }}
                      </li>
                    </ul>
                  </td>
                  <td style="font-size:8pt;padding:1mm">{{ el_data.nama_poli || '-' }}</td>
                  <td style="font-size:8pt;padding:1mm">{{ el_data.kamar || '-' }}</td>
                  <td v-if="el_data.rujuk_internal.length" style="font-size:8pt;padding:1mm">
                    <ul class="custom-li" style="font-size:8pt;padding-left:5px">
                      <li>Poli: {{ el_data.internal.poli_tujuan }}</li>
                    </ul>
                  </td>
                  <td v-else style="font-size:8pt;padding:1mm">-</td>
                  <td v-if="el_data.rujuk_eksternal.length" style="font-size:8pt;padding:1mm">
                    <ul class="custom-li" style="font-size:8pt;padding-left:5px">
                      <li>Poli: {{ el_data.eksternal.poli_tujuan }}</li>
                      <li>RS: {{ el_data.eksternal.nama_rs_tujuan }}</li>
                    </ul>
                  </td>
                  <td v-else style="font-size:8pt;padding:1mm">-</td>
                  <!-- <td style="font-size:8pt;padding:1mm">{{ el_data.rujuk_eksternal.length ? el_data.rujuk_eksternal : '-' }}</td> -->
                  <td style="font-size:8pt;padding:1mm">{{ el_data.ttd || '-' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
    <!-- PRINT BLANK -->
    <div style="position: relative; display: none;" id="blanko_rekam_medis">
      <section class="content-print A4">
        <div class="sheet">
          <div class="w-100">
            <table border="0" class="custom-table">
              <tbody>
                <tr>
                  <td style="width: 15%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img :src="logo" alt="" style="width: 100%;"></td>
                  <td style="width: 70%;vertical-align: middle;">
                    <h6 style="text-align: center;font-weight: bold;margin-bottom: 6px;font-size: 16pt;"> PEMERINTAH <span>{{ $store.state.puskesmas.nama_kota }}</span> </h6>
                    <h6 style="text-align: center;font-weight: bold;margin-bottom: 6px;font-size: 16pt;"><span>DINAS KESEHATAN</span></h6>
                    <h6 style="text-align: center;font-weight: bold;margin-bottom: 6px;font-size: 16pt;"> <span>{{ $store.state.puskesmas.nama_puskesmas }}</span> </h6>
                    <h6 style="text-align: center;font-weight: normal;font-size: 10pt;">Alamat: <span>{{ $store.state.puskesmas.alamat_puskesmas }}</span></h6>
                    <h6 style="text-align: center;font-weight: normal;font-size: 10pt;"><span>Email : emailpuskesmas@gmil.com</span> <span>Telp. : 0987654321</span></h6>
                  </td>
                  <td style="width: 15%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img src="../../cetak/puskesmas-logo.png" alt="" style="width: 100%;"></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="w-100">
            <div class="line"></div>
          </div>

          <div class="w-100" style="margin-top: 5mm;">
            <h6 style="font-size: 16pt;font-weight: bold;text-align: center;"><u>Rekam Medis Pasien</u></h6>
          </div>

          <div class="w-100-flex" style="margin-top: 5mm;">
            <div style="width: 50%;">
              <table border="0" class="custom-table">
                <tbody>
                  <tr>
                    <td style="width: 37mm;">No. eRM</td>
                    <td class="autowidth">:</td>
                    <td><span>{{ dataRm.no_rm || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Nama Pasien</td>
                    <td>:</td>
                    <td><span>{{ dataRm.nama_lengkap || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>NIK</td>
                    <td>:</td>
                    <td><span>{{ dataRm.nik || '-' }}</span></td>
                  </tr>
                  
                  <tr>
                    <td>Jenis Kelamin</td>
                    <td>:</td>
                    <td><span>{{ dataRm.jenis_kelamin == 'L' ? 'Laki - Laki' : 'Perempuan' || '-' }}</span></td>
                  </tr>

                  <!-- <tr>
                      <td>No. RM Lama</td>
                      <td>:</td>
                      <td><span>-</span></td>
                  </tr> -->

                  <!-- <tr>
                      <td>No. Dok RM</td>
                      <td>:</td>
                      <td><span>-</span></td>
                  </tr> -->

                  <!-- <tr>
                    <td>No. Asuransi</td>
                    <td>:</td>
                    <td><span>-</span></td>
                  </tr> -->
                  
                  <tr>
                    <td>Asuransi</td>
                    <td>:</td>
                    <td><span>{{ dataRm.no_bpjs ? `BPJS - ${dataRm.no_bpjs}` : "UMUM" || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>No. Handphone</td>
                    <td>:</td>
                    <td><span>{{ dataRm.no_telp || '-' }}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style="width: 50%;">
              <table border="0" class="custom-table">
                <tbody>
                  <tr>
                    <td style="width: 37mm;">Tanggal Lahir</td>
                    <td class="autowidth">:</td>
                    <td><span>{{ $moment(dataRm.tanggal_lahir).format('LL') || '-' }}</span></td>
                  </tr>
                  
                  <tr>
                    <td>Tempat Lahir</td>
                    <td>:</td>
                    <td><span>{{ dataRm.tempat_lahir || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Umur</td>
                    <td>:</td>
                    <td><span>{{ cekUmur(dataRm.tanggal_lahir) || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Golongan Darah</td>
                    <td>:</td>
                    <td><span>{{ dataRm.nama_golongan_darah || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>No. KK</td>
                    <td>:</td>
                    <td><span>{{ dataRm.no_kk || '-' }}</span></td>
                  </tr>

                  <tr>
                    <td>Alamat</td>
                    <td>:</td>
                    <td><span>{{ dataRm.alamat_sekarang || '-' }}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="w-100" style="margin-top: 5mm;">
            <table border="1" class="custom-table table-custom2">
              <thead>
                <tr>
                  <th class="autowidth" style="font-size: 8pt;padding:1mm">No.</th>
                  <th style="font-size: 8pt;padding:1mm">Tanggal</th>
                  <th style="font-size: 8pt;padding:1mm">Anamnesa</th>
                  <th style="font-size: 8pt;padding:1mm">Pemeriksaan Fisik</th>
                  <th style="font-size: 8pt;padding:1mm">Pemeriksaan Laboratorium</th>
                  <th style="font-size: 8pt;padding:1mm">Diagnosa</th>
                  <th style="font-size: 8pt;padding:1mm">Tindakan</th>
                  <th style="font-size: 8pt;padding:1mm">Resep Obat</th>
                  <th style="font-size: 8pt;padding:1mm">Tindakan</th>
                  <th style="font-size: 8pt;padding:1mm">Resep Obat</th>
                  <th style="font-size: 8pt;padding:1mm">Poli/Ruangan</th>
                  <th style="font-size: 8pt;padding:1mm">Kamar</th>
                  <th style="font-size: 8pt;padding:1mm">Rujuk Internal</th>
                  <th style="font-size: 8pt;padding:1mm">Rujuk Eksternal</th>
                  <th style="font-size: 8pt;padding:1mm">TTD</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td style="height: 190mm;padding:1mm"></td>
                  <td style="padding:1mm"></td>
                  <td style="padding:1mm"></td>
                  <td style="padding:1mm"></td>
                  <td style="padding:1mm"></td>
                  <td style="padding:1mm"></td>
                  <td style="padding:1mm"></td>
                  <td style="padding:1mm"></td>
                  <td style="padding:1mm"></td>
                  <td style="padding:1mm"></td>
                  <td style="padding:1mm"></td>
                  <td style="padding:1mm"></td>
                  <td style="padding:1mm"></td>
                  <td style="padding:1mm"></td>
                  <td style="padding:1mm"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
      
    </div>

  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
      busy: false,
      dataRm: {},
      listKunjungan: [],
      fieldsKunjungan: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: false,
          class: "table-number text-center",
        },
        {
          key: "nama_tanggal",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "instalasi",
          label: "Instalasi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_poli",
          label: "Poli / Ruangan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_puskesmas",
          label: "Puskesmas",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
      dataPrintRM: []
    }
  },
  computed: {
    logo() {
      return this.$store.state.logo;
    },
  },
  activated() {
    const vm = this
    vm.getData()
  },
  methods: {
    async getData(){
      const vm = this
      vm.busy = true
      try {
        // console.log('id', vm.$route.params.id)
        let dataRm = await vm.$axios.post('/rm/list', {rm_id: vm.$route.params.id})
        console.log('dataRm', dataRm)
        if(dataRm.data.status == 200 && dataRm.data.data.length > 0){
          vm.dataRm = dataRm.data.data[0]
          let listKunjungan = await vm.$axios.post('/kunjungan/list', {
            no_rm: vm.dataRm.no_rm, 
            halaman: '0', 
            jumlah: '10',
          })
          console.log('listKunjungan 10 kunjungan', listKunjungan)
          if(listKunjungan.data.status == 200 && listKunjungan.data.data.length > 0){
            vm.listKunjungan = listKunjungan.data.data.map(x => {
              return {
                ...x,
                nama_tanggal: vm.$moment(x.tanggal_daftar).format('LL'),
              }
            })
          }
        }else{
          vm.triggerAlert({ variant: "warning", msg: 'Rekam Medis Tidak Ditemukan', showing: true })
        }
      } catch (error) {
        console.log(error)
        vm.triggerAlert({ variant: "warning", msg: 'Terjadi kesalahan sistem', showing: true });
      } finally {
        vm.busy = false
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
    printCetak(val) {
      const options = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
          // 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
          '/style-paper-a4.css'
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: localStorage.getItem('nama_puskesmas'), // override the window title
      }
      this.$htmlToPaper(val, options);
    },

    printCetak58(val) {
      const options = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
        '/style-paper-58mm.css',
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: localStorage.getItem('nama_puskesmas') // override the window title
      }
      this.$htmlToPaper(val, options);
    },
    async getDataPrint() {
      const vm = this
      let data_rm = {
        rm_id: this.dataRm.rm_id,
      }
      try {
        const res = await vm.$axios.post('/rm/rekam_medis_pasien', data_rm)
        console.log("data rm", res.data);
        if (res.data.status == 200) {
          this.dataPrintRM = res.data.data
          for (let i = 0; i < this.dataPrintRM.length; i++) {
            let x = this.dataPrintRM[i];
            let internal = {}
            let eksternal = {}
            if(x.rujuk_eksternal.length){
              eksternal.poli_tujuan = x.rujuk_eksternal[0].nama_poli_rujuk
              if(x.rujuk_eksternal[0].rs_tujuan_rujukan_bpjs){
                eksternal.nama_rs_tujuan = x.rujuk_eksternal[0].rs_tujuan_rujukan_bpjs.nmppk
              }else{
                eksternal.nama_rs_tujuan = x.rujuk_eksternal[0].rs_tujuan_rujukan_umum
              }
            }else if(x.rujuk_internal.length){
              internal.poli_tujuan = x.rujuk_internal[0].nama_poli
            }
            x.internal = internal
            x.eksternal = eksternal
          }
          console.log(this.dataPrintRM, 'ini print rm');
          return true
        } else {
          return false
        }
      } catch (err) {
        console.log(err);
        return false
      }
    },
    async printDataRM(val) {
      const options = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
          // 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
          '/paper-a4-landscape.css'
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: localStorage.getItem('nama_puskesmas'), // override the window title
      }
      let x = await this.getDataPrint()
      // console.log(x);
      // console.log(this.dataPrintRM);
      if (x) {
        this.$htmlToPaper(val, options);
      }
        
    },
    
    cekUmur(tgl) {
      let a = this.$moment();
      let b = this.$moment(tgl);

      let diffDuration = this.$moment.duration(a.diff(b));
      
      // let tahun = diffDuration.years()
      // let bulan = diffDuration.months()
      // let hari = diffDuration.days()

      return `${diffDuration.years()} Tahun ${diffDuration.months()} Bulan ${diffDuration.days()} Hari`;
    }
  },
}
</script>